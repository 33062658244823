import Currency from "../Currency";
import { EventType, InputOptions } from "../Model";
import ServiceInterface from "./ServiceInterface";

const PriceTable = {
  [EventType.Wedding]: {
    1000000: 360,
    2000000: 378,
    3000000: 396,
    4000000: 414,
    5000000: 432,
  },
  [EventType.Other]: {
    1000000: 120,
    2000000: 126,
    3000000: 132,
    4000000: 138,
    5000000: 144,
  },
};

const MedicalMultiplier = {
  10000: 0.05,
};

const NonOwnedLiabilityCoveragePrice = {
  250000: 250,
  500000: 500,
};

class NrmaService implements ServiceInterface {
  amount(input: InputOptions): Promise<Currency> {
    const basePrice = PriceTable[input.eventType][input.gll.aggregate] || 0; // get base price for aggregate limit
    const value =
      basePrice +
      basePrice * Number(MedicalMultiplier[input.medical] || 0) + // add 5% if medical coverage is $10,000
      (input.endorsement ? 200 : 0) + // add 100$ + 100$ if "Waiver of Subrogation Endorsement and Primary Noncontributory Endorsement"
      Number(NonOwnedLiabilityCoveragePrice[input.nonOwned] || 0); // add Non-Owned / Hired Auto Liability price

    return Promise.resolve(Currency.fromDecimal(value));
  }
}

export default NrmaService;
