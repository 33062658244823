import isEqual from "lodash/isEqual";
import { InputOptions, LiquorCoverage } from "Helpers/Calculator";
import { formatDate } from "Helpers/Date";
import { intact } from "Helpers/Calculator/carriers";

type Vendor = {
  number: number;
};

export interface IntactRequest {
  riskClass: number;
  venueState: string;
  isFederalEntity: number;
  isKentuckyEntity: number;
  venueMunicipalityCode: null;
  eventDates: string[];
  numberOfDays: number;
  gll: number;
  hostLiquor: number;
  personalAndAdvertisingLimit: number;
  productsAndCompletedOperations: number;
  liquorLiability: number;
  personalProperty: number;
  cancellation: number;
  damageToRentedProperty: number;
  terrorism: number;
  performers: Vendor;
  goods: Vendor;
  food: Vendor;
  exhibitors: Vendor;
  averageDailyAttendance: number;
}

export const requestFromInput = (input: InputOptions): IntactRequest => {
  const gll = (
    intact.options.gll.find((gll) => isEqual(gll, input.gll)) ||
    intact.options.gll[0]
  ).occurrence;
  const drp =
    intact.options.drp.find((drp) => drp === input.drp) ||
    intact.options.drp[0];

  return {
    riskClass: 1,
    venueState: "OH",
    isFederalEntity: 0,
    isKentuckyEntity: 0,
    venueMunicipalityCode: null,
    eventDates: [formatDate(new Date())],
    numberOfDays: 1,
    gll,
    hostLiquor: input.liquor !== LiquorCoverage.LiquorLiability ? 1 : 0,
    personalAndAdvertisingLimit: 0,
    productsAndCompletedOperations: 0,
    liquorLiability: input.liquor === LiquorCoverage.LiquorLiability ? 1 : 0,
    personalProperty: 0,
    cancellation: 0,
    damageToRentedProperty: drp,
    terrorism: 0,
    performers: { number: 0 },
    goods: { number: 0 },
    food: { number: 0 },
    exhibitors: { number: 0 },
    averageDailyAttendance: 100,
  };
};
