import { Carrier, CarrierResult, GllOption, InputOptions } from "./Model";
import resolve, { getEligibleOptions } from "./Resolver";
import isEqual from "lodash/isEqual";

const getValues = (carrier: Carrier, input: InputOptions) => {
  const values = { ...input };

  // if carrier doesn't support input GLL but has higher option, use it instead
  if (
    !carrier.options.gll.some((option: GllOption) => isEqual(option, input.gll))
  ) {
    const gll = carrier.options.gll.find(
      (option: GllOption) =>
        option.occurrence >= input.gll.occurrence &&
        option.aggregate >= input.gll.aggregate,
    );
    if (gll) {
      values.gll = gll;
    }
  }
  // if carrier doesn't support input DRP but has higher option, use it instead
  if (!carrier.options.drp.includes(input.drp)) {
    const drp = carrier.options.drp.find(
      (option: number) => option > input.drp,
    );
    if (drp) {
      values.drp = drp;
    }
  }
  // if carrier doesn't support input medical but has higher option, use it instead
  if (!carrier.options.medical.includes(input.medical)) {
    const medical = carrier.options.medical.find(
      (option: number) => option > input.medical,
    );
    if (medical) {
      values.medical = medical;
    }
  }

  return values;
};

const calculate = (input: InputOptions): Promise<CarrierResult[]> => {
  const carriers = resolve(input);

  return Promise.all(
    carriers.map(async (carrier: Carrier): Promise<CarrierResult> => {
      const values = getValues(carrier, input);

      return {
        carrierCode: carrier.carrierCode,
        values,
        options: getEligibleOptions(carrier, values),
        amount: await carrier.service.amount(input),
        logo: carrier.logo,
        url: carrier.url,
      };
    }),
  );
};

export default calculate;
