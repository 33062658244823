import { LiquorCoverage } from "../Helpers/Calculator";

export const liquorOptions = [
  {
    value: LiquorCoverage.None,
    label: LiquorCoverage.None,
  },
  {
    value: LiquorCoverage.HostLiquor,
    label: LiquorCoverage.HostLiquor,
  },
  {
    value: LiquorCoverage.LiquorLiability,
    label: LiquorCoverage.LiquorLiability,
  },
];
