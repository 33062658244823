import { Reactable, EffectsAndSources } from "@reactables/core";
import { build, ControlModels, RxFormActions } from "@reactables/forms";
import { formConfig } from "./Configs/form.config";
import { validatorFns } from "@jauntin/reactables";
import { InputOptions } from "../../../../Helpers/Calculator";

export interface RxCalculationFormActions extends RxFormActions {}

export type RxCalculationFormState = ControlModels.Form<{
  calculator: InputOptions;
}>;

export const RxCalculationForm = (
  options?: { debug?: boolean } & EffectsAndSources,
) =>
  build(formConfig, {
    name: "rxCalculationForm",
    ...options,
    reducers: {
      resetCalculator: ({ resetControl }, state) => {
        return resetControl(state, []);
      },
    },
    providers: {
      // Provide validators when initializing the form
      validators: {
        ...validatorFns(),
      },
    },
  }) as Reactable<RxCalculationFormState, RxCalculationFormActions>;
