import axios, { AxiosInstance } from "axios";
import paramsSerializer from "./paramsSerializer";

class API {
  private instance: AxiosInstance;

  static defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  constructor(URL: string) {
    const config = {
      baseURL: URL,
      headers: API.defaultHeaders,
    };
    this.instance = axios.create(config);
  }

  get = ({ location, params }) => {
    return this.instance.get(location, {
      params,
      paramsSerializer,
    });
  };
}

export default API;
