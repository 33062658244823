import { EventType, InputOptions } from "Helpers/Calculator";
import { formatDate } from "Helpers/Date";
import { gbli } from "Helpers/Calculator/carriers";
import isEqual from "lodash/isEqual";

export interface GbliRequest {
  venueState: string;
  isKentuckyEntity: number;
  venueMunicipalityCode: null;
  eventType: string;
  eventDates: string[];
  personalAndAdvertisingLimit: number;
  productsAndCompletedOperations: number;
  gll: { occurrence: number; aggregate: number };
  cannabis: number;
  averageDailyAttendance: number;
  damageToRentedPremises: number;
  terrorism: number;
  medical: number;
}

export const requestFromInput = (input: InputOptions): GbliRequest => {
  const gll =
    gbli.options.gll.find((gll) => isEqual(gll, input.gll)) ||
    gbli.options.gll[0];
  const drp =
    gbli.options.drp.find((drp) => drp === input.drp) || gbli.options.drp[0];

  return {
    venueState: "OH",
    isKentuckyEntity: 0,
    venueMunicipalityCode: null,
    eventType:
      input.eventType === EventType.Wedding
        ? "wedding-ceremony-reception"
        : "bakefood-sale",
    eventDates: [formatDate(new Date())],
    personalAndAdvertisingLimit: 0,
    productsAndCompletedOperations: 0,
    gll,
    cannabis: 0,
    averageDailyAttendance: 100,
    damageToRentedPremises: drp,
    terrorism: 0,
    medical: 5000,
  };
};
