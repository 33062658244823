import isEqual from "lodash/isEqual";
import { InputOptions, CarrierOptions, Carrier, EventType } from ".";
import carriers from "./carriers";

export const getEligibleOptions = (
  carrier: Carrier,
  input: InputOptions,
): CarrierOptions => {
  const keys = Object.keys(input);
  const eligibility = keys.map((key) =>
    carrier.options[key].some((v: any) => isEqual(v, input[key])),
  );

  const obj = {};
  for (let i = 0; i < keys.length; i++) {
    obj[keys[i]] = eligibility[i];
  }
  return obj as CarrierOptions;
};

const isNrmaEligible = (input: InputOptions): boolean => {
  return (
    (input.gll.aggregate >= 3000000 && input.gll.aggregate <= 5000000) ||
    input.medical === 10000 ||
    input.nonOwned > 0
  );
};

const resolve = (input: InputOptions): Carrier[] => {
  const isNrmaSupported = isNrmaEligible(input);
  const fullConcideCarrier = Object.values(carriers).find((carrier: Carrier) =>
    Object.values(getEligibleOptions(carrier, input)).every((v: any) => !!v),
  );
  const partialConcideCarriers = Object.values(carriers).filter(
    (carrier: Carrier) =>
      Object.values(getEligibleOptions(carrier, input)).some((v: any) => !!v) &&
      carrier.carrierCode !== carriers.nrma.carrierCode,
  );

  if (
    fullConcideCarrier &&
    (fullConcideCarrier.carrierCode !== carriers.nrma.carrierCode ||
      isNrmaSupported)
  ) {
    return [fullConcideCarrier];
  }

  if (isNrmaSupported) {
    const nrmaCarrier =
      input.eventType === EventType.Other
        ? {
            ...carriers.nrma,
            url: process.env.REACT_APP_NRMA_OTHER_TYPE_PRODUCTION_URL,
          }
        : carriers.nrma;

    return [partialConcideCarriers[0], nrmaCarrier];
  }

  return partialConcideCarriers;
};

export default resolve;
