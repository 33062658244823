import API from "../Client/API";
import { requestFromInput } from "../Client/Models/Intact";
import { InputOptions } from "../Model";
import Currency from "../Currency";
import ServiceInterface from "./ServiceInterface";

class IntactService implements ServiceInterface {
  static defaultAmount = 75;
  private api: API;

  constructor() {
    if (!process.env.REACT_APP_ONEBEACON_API_LOCATION) {
      throw new Error("Invalid REACT_APP_ONEBEACON_API_LOCATION");
    }
    this.api = new API(process.env.REACT_APP_ONEBEACON_API_LOCATION);
    return this;
  }

  async amount(input: InputOptions): Promise<Currency> {
    try {
      const response = await this.api.get({
        location: "/quote/calculate",
        params: requestFromInput(input),
      });
      return Currency.fromInt(response.data.total.amount);
    } catch (error) {
      return Currency.fromDecimal(IntactService.defaultAmount);
    }
  }
}

export default IntactService;
