import { Carrier, EventType, LiquorCoverage } from "./Model";
import GbliService from "./Services/GbliService";
import IntactService from "./Services/IntactService";
import NrmaService from "./Services/NrmaService";
import GatherGuardLogo from "../../Assets/gather-guard-logo.svg";
import SpecialInsuranceLogo from "../../Assets/special-insurance-logo.svg";
import NrmaLogo from "../../Assets/nrma-logo.png";

const gbli: Carrier = {
  carrierCode: "gbli",
  service: new GbliService(),
  options: {
    eventType: [EventType.Wedding, EventType.Other],
    gll: [
      { occurrence: 500000, aggregate: 1000000 },
      { occurrence: 1000000, aggregate: 1000000 },
      { occurrence: 1000000, aggregate: 2000000 },
    ],
    drp: [300000, 500000, 1000000],
    medical: [5000],
    liquor: [LiquorCoverage.None, LiquorCoverage.HostLiquor],
    endorsement: [0, 1],
    nonOwned: [0],
  },
  logo: SpecialInsuranceLogo,
  url: process.env.REACT_APP_GBLIEVENTS_PRODUCTION_URL,
};

const intact: Carrier = {
  carrierCode: "intact",
  service: new IntactService(),
  options: {
    eventType: [EventType.Wedding, EventType.Other],
    gll: [
      { occurrence: 1000000, aggregate: 2000000 },
      { occurrence: 2000000, aggregate: 2000000 },
    ],
    drp: [250000, 1000000],
    medical: [0],
    liquor: [
      LiquorCoverage.None,
      LiquorCoverage.HostLiquor,
      LiquorCoverage.LiquorLiability,
    ],
    endorsement: [0],
    nonOwned: [0],
  },
  logo: GatherGuardLogo,
  url: process.env.REACT_APP_ONEBEACON_PRODUCTION_URL,
};

const nrma: Carrier = {
  carrierCode: "nrma",
  service: new NrmaService(),
  options: {
    eventType: [EventType.Wedding, EventType.Other],
    gll: [
      { occurrence: 1000000, aggregate: 1000000 },
      { occurrence: 1000000, aggregate: 2000000 },
      { occurrence: 1000000, aggregate: 3000000 },
      { occurrence: 1000000, aggregate: 4000000 },
      { occurrence: 1000000, aggregate: 5000000 },
    ],
    drp: [300000],
    medical: [0, 10000],
    liquor: [LiquorCoverage.None, LiquorCoverage.HostLiquor],
    endorsement: [0, 1],
    nonOwned: [0, 250000, 500000],
  },
  logo: NrmaLogo,
  url: process.env.REACT_APP_NRMA_WEDDING_PRODUCTION_URL,
};

const carriers = {
  [gbli.carrierCode]: gbli,
  [intact.carrierCode]: intact,
  [nrma.carrierCode]: nrma,
};

export { gbli, intact, nrma };

export default carriers;
