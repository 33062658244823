import { RxBuilder, Reactable } from "@reactables/core";
import { map, mergeMap, catchError } from "rxjs/operators";
import { from, of, combineLatest, Subject } from "rxjs";
import {
  load,
  loadError,
  loadSuccess,
  LoadableState,
  lodableInitialState,
} from "@jauntin/reactables";
import {
  RxCalculationForm,
  RxCalculationFormActions,
  RxCalculationFormState,
} from "../RxCalculatorForm/RxCalculatorForm";
import { CarrierResult, InputOptions } from "../../../../Helpers/Calculator";

export interface RxCalculatorActions {
  form: RxCalculationFormActions;
  submit: (payload: InputOptions) => void;
  resetState: () => void;
}

export interface RxCalculatorState {
  form: RxCalculationFormState;
  carriers: LoadableState<CarrierResult[]>;
}

export const RxCalculator = (
  calculate: (input: InputOptions) => Promise<CarrierResult[]>,
  {
    debug,
  }: {
    debug?: {
      form?: boolean;
      carriers?: boolean;
    };
  },
): Reactable<RxCalculatorState, RxCalculatorActions> => {
  const resetCalculator$ = new Subject<undefined>();

  const resetSource$ = resetCalculator$.pipe(
    map(() => ({ type: "resetCalculator" })),
  );

  // Rx manages form state and validations
  const rxForm = RxCalculationForm({
    debug: debug?.form,
    sources: [resetSource$],
  });

  // Rx manages form submission
  const rxSubmission = RxBuilder({
    name: "rxSubmission",
    initialState: lodableInitialState,
    debug: debug?.carriers,
    reducers: {
      resetCalculator: () => lodableInitialState,
      submitForm: {
        reducer: load,
        effects: [
          (submission$) =>
            submission$.pipe(
              mergeMap(({ payload }: { payload: InputOptions }) => {
                return from(calculate(payload)).pipe(
                  map((data: CarrierResult[]) => ({
                    type: "submitSuccess",
                    payload: data,
                  })),
                  catchError((error: unknown) =>
                    of({
                      type: "submitFailure",
                      payload: error,
                    }),
                  ),
                );
              }),
            ),
        ],
      },
      submitSuccess: loadSuccess,
      submitFailure: loadError,
    },
  });

  // Combine states of reactables
  const state$ = combineLatest({
    form: rxForm[0],
    carriers: rxSubmission[0],
  });

  // Expose public action methods
  const actions = {
    form: rxForm[1],
    submit: rxSubmission[1].submitForm,
    resetState: rxSubmission[1].resetCalculator,
  };

  return [state$, actions];
};
