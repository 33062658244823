import React from "react";
import { CarrierResult, Currency } from "../../Helpers/Calculator";
import { Button } from "@jauntin/react-ui";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react-helpers";
import { nrma, gbli } from "Helpers/Calculator/carriers";

interface CarrierCalculationsProps {
  carrier: CarrierResult;
}

const CarrierCalculations = ({ carrier }: CarrierCalculationsProps) => {
  const [showDetails, showDetailsToggle] = useReactable(RxToggle, false);

  const isEndorsementIncluded = (carrier: CarrierResult) => {
    if (carrier.carrierCode === nrma.carrierCode) {
      return carrier.values.endorsement;
    }

    return carrier.carrierCode === gbli.carrierCode;
  };

  return (
    <div className={`carrier-group ${carrier.carrierCode}`}>
      {carrier.logo && carrier.url && (
        <div className="logo">
          <a href={carrier.url} target="_blank" rel="noreferrer">
            <img src={carrier.logo} alt="Logo" />
          </a>
          <span>
            *Click the icon to purchase event insurance coverage online
          </span>
        </div>
      )}

      <div className="price-block">
        Pricing Starts at <span>{carrier.amount.formatDollars()}</span>
      </div>

      {carrier.carrierCode === gbli.carrierCode && (
        <div className="info-block">
          Coverage can extend beyond midnight depending on state liquor laws, in
          which case only 1 day of coverage is required.
        </div>
      )}

      <Button
        className="toggle-button"
        text={showDetails ? "Hide details" : "View details"}
        onClick={showDetailsToggle.toggle}
      />

      {showDetails && (
        <div className="result-wrapper">
          <div className="column">
            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.eventType ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.eventType ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">{carrier.values.eventType} Event Type</div>
            </div>

            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.gll ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.gll ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">
                {Currency.fromDecimal(carrier.values.gll.occurrence).dollars()}/
                {Currency.fromDecimal(carrier.values.gll.aggregate).dollars()}{" "}
                General Liability Limit
              </div>
            </div>

            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.medical ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.medical ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">
                {Currency.fromDecimal(carrier.values.medical).dollars()} Medical
                Limit
              </div>
            </div>

            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.drp ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.drp ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">
                {Currency.fromDecimal(carrier.values.drp).dollars()} Damage to
                Rented Premises Limit
              </div>
            </div>
          </div>

          <div className="column">
            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.liquor ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.liquor ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">
                Liquor Coverage - {carrier.values.liquor}
              </div>
            </div>

            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.endorsement ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.endorsement ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">
                Waiver of Subrogation Endorsement and Primary Noncontributory
                Endorsement -{" "}
                {isEndorsementIncluded(carrier) ? "Included" : "Not Included"}
              </div>
            </div>

            <div className="value-block">
              <div
                className={`checkmark ${carrier.options.nonOwned ? "green" : "orange"}`}
              >
                <i
                  className={`fas ${carrier.options.nonOwned ? "fa-check" : "fa-xmark"}`}
                ></i>
              </div>
              <div className="value">
                {Currency.fromDecimal(carrier.values.nonOwned).dollars()}{" "}
                Non-Owned / Hired Auto Liability Limit
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarrierCalculations;
