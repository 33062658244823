import React from "react";
import { useReactable } from "@reactables/react-helpers";
import { Form, Field } from "@reactables/react-forms";
import { AsyncButton, Button, SelectList, RadioInput } from "@jauntin/react-ui";
import { gllOptions } from "../../Constants/gllOptions";
import { medicalOptions } from "../../Constants/medicalOptions";
import { drpOptions } from "../../Constants/drpOptions";
import { liquorOptions } from "../../Constants/liquorOptions";
import { endorsementOptions } from "../../Constants/endorsementOptions";
import { nonOwnedOptions } from "../../Constants/nonOwnedOptions";
import calculate, {
  CarrierResult,
  InputOptions,
} from "../../Helpers/Calculator";
import { RxCalculator } from "../../Features/Calculator/Rx/RxCalculator/RxCalculator";
import CarrierCalculations from "./CarrierCalculations";
import customStyles from "../../Helpers/ReactCustomStyles";
import PopoverTrigger from "../Popover/PopoverTrigger";
import { EventType } from "../../Helpers/Calculator";

const Calculator = () => {
  const rxCalculator = useReactable(RxCalculator, calculate, {});
  const [state, actions] = rxCalculator;

  if (!state) return;

  const { form, carriers } = state;

  return (
    <div>
      <h4>
        {!carriers.data && (
          <>
            Complete the quick form below and it will recommend an insurer that
            could meet your needs:
          </>
        )}
        {carriers.data && (
          <>Event insurance carriers that could meet your needs:</>
        )}
      </h4>
      {!carriers.data && (
        <div className="calculator-wrapper">
          <Form rxForm={[form, actions.form]}>
            <div className="event-type">
              Event Type:
              <Field
                id={`eventType${EventType.Wedding}`}
                name="calculator.eventType"
                component={RadioInput}
                label={EventType.Wedding}
                value={EventType.Wedding}
                checked={
                  form.calculator.value["eventType"] === EventType.Wedding
                }
              />
              <Field
                id={`eventType${EventType.Other}`}
                name="calculator.eventType"
                component={RadioInput}
                label={EventType.Other}
                value={EventType.Other}
                checked={form.calculator.value["eventType"] === EventType.Other}
              />
            </div>
            <div className="inner-wrapper">
              <div className="big-column">
                <div className="input-group">
                  <PopoverTrigger
                    id="gll-popover"
                    key="gll-popover-key"
                    content="Event Liability Insurance works to protect the event holder from claims made against them due to injury to guests and/or damage to the venue."
                  />
                  <div className="label padding-15-on-mobile">
                    <label htmlFor="gll">
                      <a
                        href="https://jauntin.com/introduction-to-public-liability-insurance/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        General Liability
                      </a>{" "}
                      Limit:
                      <br />
                      <span>(Occurrence/Aggregate)</span>
                    </label>
                  </div>
                  <div className="input">
                    <Field
                      id="gll"
                      name="calculator.gll"
                      component={SelectList}
                      options={gllOptions}
                      customStyles={customStyles}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <PopoverTrigger
                    id="medical-popover"
                    key="medical-popover-key"
                    content="The Medical Payments component provides additional coverage for out of pocket medical expenses and helps deter lawsuits."
                  />
                  <div className="label">
                    <label htmlFor="medical">Medical Limit:</label>
                  </div>
                  <div className="input">
                    <Field
                      id="medical"
                      name="calculator.medical"
                      component={SelectList}
                      options={medicalOptions}
                      customStyles={customStyles}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <PopoverTrigger
                    id="drp-popover"
                    key="drp-popover-key"
                    content="Applies only to damages for which you are determined to be legally liable."
                  />
                  <div className="label">
                    <label htmlFor="drp">
                      Damage to Rented Premises Limit:
                    </label>
                  </div>
                  <div className="input">
                    <Field
                      id="drp"
                      name="calculator.drp"
                      component={SelectList}
                      options={drpOptions}
                      customStyles={customStyles}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <PopoverTrigger
                    id="nonOwned-popover"
                    key="nonOwned-popover-key"
                    content="The term “hired auto” refers to any vehicles that you hire, rent or borrow."
                  />
                  <div className="label">
                    <label htmlFor="nonOwned">
                      Non-Owned / Hired Auto Liability Limit:
                    </label>
                  </div>
                  <div className="input">
                    <Field
                      id="nonOwned"
                      name="calculator.nonOwned"
                      component={SelectList}
                      options={nonOwnedOptions}
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="small-column">
                <div className="input-group">
                  <PopoverTrigger
                    id="liquor-popover"
                    key="liquor-popover-key"
                    content="Host liquor is primarily for events where alcohol is not sold, but allow for its consumption. Liquor liability, on the other hand, is for events where alcohol is sold."
                  />
                  <div className="label">
                    <label htmlFor="liquor">
                      <a
                        href="https://jauntin.com/event-insurance-exploring-host-liquor-liability-vs-liquor-liability/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Liquor Coverage
                      </a>{" "}
                      needed?
                    </label>
                  </div>
                  <div className="input">
                    <Field
                      id="liquor"
                      name="calculator.liquor"
                      component={SelectList}
                      options={liquorOptions}
                      customStyles={customStyles}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <PopoverTrigger
                    id="endorsement-popover"
                    key="endorsement-popover-key"
                    content="Waiver of Transfer of Rights Against Others to Us is an endorsement that prevents an insurer from recovering the money they’ve paid out on a claim from a negligent third party. So in the case of special events, the event insurance policy insurer shouldn’t be able to recover money from the venue or hosts. Primary designates that one party’s liability policy is responsible for responding to a claim first before another entity’s policy applies. Noncontributory stops the primary party’s insurer from seeking contribution from the other entity’s policy for paying a claim."
                  />
                  <div className="label">
                    <label htmlFor="endorsement">
                      <a
                        href="https://jauntin.com/event-insurance-waiver-of-transfer-of-rights-against-others-to-us-endorsement-form-cg-24-04-what-is-it/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Waiver of Subrogation Endorsement
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://jauntin.com/event-insurance-primary-noncontributory-endorsement-what-is-it/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Primary Noncontributory Endorsement
                      </a>{" "}
                      needed?
                    </label>
                  </div>
                  <div className="input">
                    <Field
                      id="endorsement"
                      name="calculator.endorsement"
                      component={SelectList}
                      options={endorsementOptions}
                      customStyles={customStyles}
                    />
                  </div>
                </div>

                <div>
                  <AsyncButton
                    onClick={() => {
                      actions.submit(form.calculator.value as InputOptions);
                    }}
                    spinning={state.carriers.loading}
                    spinningText="Submitting..."
                    disabled={!form.calculator.valid}
                    className="btn btn-primary submit-button"
                  >
                    {!state.carriers.loading && "Submit"}
                  </AsyncButton>
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
      {carriers.data && (
        <>
          <div className="calculations-wrapper">
            {carriers.data.map((carrier: CarrierResult) => (
              <CarrierCalculations
                carrier={carrier}
                key={carrier.carrierCode}
              />
            ))}
          </div>
          <div className="button-place">
            <Button
              onClick={actions.resetState}
              text="Back to Form"
              className="back-to-form-button"
            />
          </div>
        </>
      )}
      <div className="disclaimer">
        Disclaimer: The recommendations are for informational purposes only and
        should not be construed as advice or guarantees on any subject matter
        and contains general information which may not be current or accurate.
        For specific questions about insurance and any requirements, please
        contact the insurer or your licensed insurance broker or agent directly.
      </div>
    </div>
  );
};

export default Calculator;
