import React from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Calculator from "./Components/Calculator/Calculator";
import "./App.scss";
import { useScript } from "@uidotdev/usehooks";

const App = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const themeSlug = searchParams.get("theme");
  const theme = themeSlug === "jauntin" ? {} : {};

  useScript(
    "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.contentWindow.min.js",
  );

  return (
    <ThemeProvider theme={theme}>
      <div id="page-wrapper" className={`page-index`}>
        <Calculator />
      </div>
    </ThemeProvider>
  );
};

export default App;
