import { group, control } from "@reactables/forms";
import { EventType, LiquorCoverage } from "../../../../../Helpers/Calculator";

export const formConfig = group({
  controls: {
    calculator: group({
      controls: {
        eventType: control({
          initialValue: EventType.Wedding,
          validators: ["required"],
        }),
        gll: control({
          initialValue: { occurrence: 500000, aggregate: 1000000 },
          validators: ["required"],
        }),
        drp: control({
          initialValue: 250000,
          validators: ["required"],
        }),
        medical: control({
          initialValue: 0,
          validators: ["required"],
        }),
        liquor: control({
          initialValue: LiquorCoverage.None,
          validators: ["required"],
        }),
        endorsement: control({
          initialValue: 1,
          validators: ["required"],
        }),
        nonOwned: control({
          initialValue: 0,
          validators: ["required"],
        }),
      },
    }),
  },
});
