import { ParamsSerializerOptions } from "axios";

const paramsSerializer = (
  params: Record<string, any>,
  options?: ParamsSerializerOptions,
): string => {
  const param = (params: Record<string, any>, keys = [], isArray = false) => {
    const p = Object.keys(params)
      .map((key, index) => {
        const val = params[key];

        if (
          Object.prototype.toString.call(val) === "[object Object]" ||
          Array.isArray(val)
        ) {
          if (Array.isArray(params)) {
            keys.push(index);
          } else {
            keys.push(key);
          }
          return param(val, keys, Array.isArray(val));
        }
        let tKey = encodeURIComponent(key);

        if (keys.length > 0) {
          const tKeys = isArray ? keys : [...keys, key];
          tKey = tKeys.reduce((str, k) => {
            return str === ""
              ? encodeURIComponent(k)
              : `${str}[${encodeURIComponent(k)}]`;
          }, "");
        }
        if (isArray) {
          return `${tKey}[]=${encodeURIComponent(val)}`;
        }
        return `${tKey}=${encodeURIComponent(val)}`;
      })
      .join("&");

    keys.pop();
    return p;
  };

  return param(params);
};

export default paramsSerializer;
