import React from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

const PopoverTrigger = ({ id, content }) => {
  return (
    <div>
      <OverlayTrigger
        trigger={["hover", "click"]}
        placement="auto"
        overlay={
          <Popover id={id} className="popover-content">
            {content}
          </Popover>
        }
        rootClose
        delay={200}
      >
        <Button variant="link" className="p-1 d-inline">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM8.25 12.75H6.75V11.25H8.25V12.75ZM9.8025 6.9375L9.1275 7.6275C8.5875 8.175 8.25 8.625 8.25 9.75H6.75V9.375C6.75 8.55 7.0875 7.8 7.6275 7.2525L8.5575 6.3075C8.835 6.0375 9 5.6625 9 5.25C9 4.425 8.325 3.75 7.5 3.75C6.675 3.75 6 4.425 6 5.25H4.5C4.5 3.5925 5.8425 2.25 7.5 2.25C9.1575 2.25 10.5 3.5925 10.5 5.25C10.5 5.91 10.23 6.51 9.8025 6.9375Z"
              fill="#002B7D"
            />
          </svg>
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default PopoverTrigger;
